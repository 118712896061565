import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import CategoryCover from "../../components/blog/category-cover"
import SEO from "../../components/seo"

import "./index.css"

var dateFormat = require('dateformat');

function BlogIndex({ data }) {
  const { edges: posts } = data.allMdx;
  let blogCount = 0;
  return (
    <Layout>
      <CategoryCover blogCategory="score"></CategoryCover>
      <SEO title={"Credit Score - Credit 101"} keywords={['credit score', 'build credit', 'bad credit', 'credit card', 'FICO', 'credit karma', 'nerdwallet']} description="Understand what's behind the numbers." />
      <div className="articles-wrapper">
        <div className="articles flex flex-wrap overflow-hidden p-8 sm:px-12 md:px-16 lg:px-32 xl:px-48">
          {posts.map(({ node }) => {
            blogCount += 1
            const { title, date, type, coverImgUrl } = node.frontmatter
            let displayPublishDate = new Date(date);
            displayPublishDate = dateFormat(displayPublishDate, "mmmm d, yyyy");

            let displayArticleType = "";
            let displayArticleTypeClasses = "article-type uppercase text-xs font-bold";
            switch (type) {
              case "build":
                displayArticleType = "Build Credit";
                displayArticleTypeClasses += " build-type";
                break;
              case "cards":
                displayArticleType = "Credit Cards";
                displayArticleTypeClasses += " cards-type";
                break;
              case "score":
                displayArticleType = "Credit Score";
                displayArticleTypeClasses += " score-type";
                break;
              default:
                break;
            }

            const articleCoverStyle = {
              backgroundImage: 'url(' + coverImgUrl + ')'
            };

            let displayArticleItemClasses = "article-index-item w-full overflow-hidden mb-6 sm:w-full md:w-1/2";
            if (blogCount % 3 === 0) {
              displayArticleItemClasses += " article-index-item-right";
            }
            else if (blogCount % 3 === 1){
              displayArticleItemClasses += " article-index-item-left";
            }

            return (
              <a href={"../" + node.fields.blogPath} className={displayArticleItemClasses} key={node.id}>
                <div className="article-cover" style={articleCoverStyle}></div>
                <div className="article-description px-4 py-6">
                  <div className={displayArticleTypeClasses}><span>{displayArticleType}</span></div>
                  <div className="font-bold text-xl py-1"><span>{title}</span></div>
                  <div className="article-publish-date text-xs">{displayPublishDate}</div>

                </div>
              </a>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query scoreCreditIndex {
    allMdx(
      limit: 10
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {fields: {blogType: {eq: "score"}}}
    ) {
      edges {
        node {
          id
          fields {
            blogType
            blogPath
          }
          frontmatter {
            title
            author
            date
            type
            coverImgUrl
          }
        }
      }
    }
  }
`
