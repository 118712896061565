import React from "react"
import  "./category-cover.css"


const CategoryCover = ({blogCategory}) => {
  let coverTitle = "";
  let coverSubtitle = "";
  let wrapperClasses = "category-cover px-4 md:pl-8 md:pr-0 lg:pl-12 flex";
  let descriptionClasses = "description flex-auto py-24 md:py-32 lg:py-48 mt-8";
  switch (blogCategory)
  {
    case "build":
      coverTitle = "Build Credit";
      coverSubtitle = "Start your credit journey here.";
      descriptionClasses += " description-build";
      wrapperClasses += " bg-blue-500";
      break;
    case "cards":
      coverTitle = "Credit Cards";
      coverSubtitle = "Maximize your payments with credit cards.";
      descriptionClasses += " description-cards";
      wrapperClasses = "category-cover category-cover-cards bg-blue-500 px-4 md:px-8 lg:px-12 flex";
      break;
    case "score":
      coverTitle = "Credit Score";
      coverSubtitle = "Understand what's behind the numbers.";
      descriptionClasses += " description-score";
      wrapperClasses += " category-cover-score";
      wrapperClasses = "category-cover category-cover-score px-4 md:px-8 lg:px-12 flex";
      break;
    default: break;
  }

  return (
    <div className={wrapperClasses}>
      <div className={descriptionClasses}>
        <div><h1 className="text-2xl md:text-4xl font-extrabold">{coverTitle}</h1></div>
        <div><h3 className="text-lg md:text-xl font-normal"> {coverSubtitle}</h3></div>
      </div>
    </div>
  )
}

export default CategoryCover
